/** External Imports */
import { Col, Container, Row, Form, Input } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  imageDeleteRequest,
  imageSendRequest,
  linkGetRequest,
  reseDataRequest,
} from "../../store/bussiness/bussiness.action";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";

/** Internal Imports */

function ImageUpload({ onNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  const imageRef = useRef(null);
  const { id } = useParams();

  /* Selectors */
  const { getBussinessDetails, delSucces } = useSelector(bussnessCatSelector);
  console.log(delSucces, 7888);
  /* States */
  // const [files, setFiles] = useState(null);
  // console.log(files, "tyghbnj")
  /* Function */

  const [images, setImages] = useState([]);
  const [getImage, setGetImage] = useState([]);
  console.log(getImage, 67898978);
  /* Dropzone Setup */
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const newImages = acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      setImages((prevImages) => [...prevImages, ...newImages]);
    },
  });

  const handleRemoveImage = (imageToRemove) => {
    setImages(images.filter((image) => image !== imageToRemove));
  };
  const handleDelImage = (dekl, ind) => {
    console.log(dekl, ind, 78223);
    let inot = dekl.slice(22);
    console.log(inot, "78hghj");
    let payload = {
      name: inot,
    };
    dispatch(imageDeleteRequest(payload));
    dispatch(reseDataRequest());
  };
  const handleUploadClick = () => {
    if (images?.length > 0) {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("files", image);
      });
      formData.append("arabicStatus", arabicStatus);

      dispatch(imageSendRequest(formData));
    }
  };

  const handleClickNext = () => {
    onNext();
  };
  /* FormData */

  /* UseEffect */
  useEffect(() => {
    dispatch(linkGetRequest(id));
  }, [id]);
  useEffect(() => {
    let imagePaths = getBussinessDetails?.media?.image_paths;
    const imageArray = imagePaths?.split(",");
    console.log(imageArray, "uhbjhuj");

    const baseURL = process.env.REACT_APP_NODELOCALASSETURL;
    const fullImageURLs = imageArray?.map((path) => `${baseURL}${path.trim()}`);
    console.log(fullImageURLs, 799090);
    setGetImage(fullImageURLs);
  }, [getBussinessDetails]);
  useEffect(() => {
    if (delSucces) {
      dispatch(linkGetRequest(id));
    }
  }, [id]);
  return (
    <div>
      <div className="add-listing-form form-space  mb-60 wow fadeInUp">
        <h4 className={`${i18n.language == "ar" ? "arabic_title" : "title"}`}>
          {t("gallery") + " " + t("images")}
        </h4>

        <div className="filled form-group tooltip-end-top">
          <label className="label-style">
            {t("upload") + " " + t("images")}:
          </label>
          <Row>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} className="border-business" />
              <p>Drag 'n' drop some images here, or click to select images</p>
            </div>
            <div className="slidingClass" id="sliderContainer">
              <div>
                {images.length > 0 &&
                  images.map((image, index) => (
                    <div key={index} className="imgdiv">
                      <img src={image.preview} alt={`preview-${index}`} />
                      <button
                        onClick={() => handleRemoveImage(image)}
                        className="imgcancel"
                      >
                        X
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="slidingClass" id="sliderContainer">
              {getImage?.length > 0 &&
                getImage?.map((ele, index) => (
                  <div key={index} className="imgdiv">
                    <img src={ele} />
                    <button
                      onClick={() => handleDelImage(ele, index)}
                      className="imgcancel"
                    >
                      X
                    </button>
                  </div>
                ))}
            </div>

            {images?.length == null && (
              <p className="text-danger">{t("image_error")}</p>
            )}
          </Row>
          <Col lg={6}>
            <button onClick={handleUploadClick} className="main-btn mt-20">
              {t("upload")}
            </button>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
