import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import app from "../../assets/images/app_icon.png";
import {
  faBullhorn,
  faChartColumn,
  faUser,
  faClose,
  faLanguage,
  faChevronDown,
  faBell,
  faBars,
  faBuilding,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

/** Internal Imports */
import profileimg from "../../assets/images/agent.svg";
import "../../assets/css/main-pages.css";
import {
  loginByMobileRequest,
  loginUserRequest,
  resetStateRequest,
  resetSuccess,
} from "../../store/business_user/auth.action";
import {
  getUserInfo,
  getAccessToken,
  getAuthLoading,
  getUserAllInfo,
} from "../../store/business_user/auth.selector";
import {
  businesssotpValidator,
  businesssigninValidator,
} from "../../validators/Validators";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import {
  userRegisterRequest,
  userLoginRequest,
  resetUserDataRequest,
  loginStateReset,
} from "../../store/user/user.action";
import { userSelector } from "../../store/user/user.model";
import { linkGetRequest } from "../../store/bussiness/bussiness.action";
import {
  getUserDetailLoading,
  setMobileState,
} from "../../store/user/user.selector";
import { getUserDetailRequest } from "../../store/UserDetails/userdetail.action";
import { userDetailSelector } from "../../store/UserDetails/userdetail.model";
import { authSelector } from "../../store/business_user/auth.model";
import { useTranslation } from "react-i18next";
import "../../assets/css/responsivejust.css";

function Header() {
  const [visible, setVisible] = useState(false);
  function Dropdown() {
    setVisible(!visible);
  }
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lngcode, setLngcode] = useState("ar");
  let arabicStatus = i18n.language == "ar";
  /** Selector Values */
  const { isLoading, userLoginData, email, isLoginLoading } =
    useSelector(authSelector);
  const { isUserLoginLoading, loginSuccess, user_email } =
    useSelector(userSelector);
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { getUserData } = useSelector(userDetailSelector);
  /** States */
  const [logoutShow, setlogoutShow] = useState(false);
  const [userRegisterShow, setuserRegisterShow] = useState(false);
  const [userLoginShow, setuserLoginShow] = useState(false);
  const [otpShow, setotpShow] = useState(false);
  const [loginShow, setloginShow] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState({});
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");
  const navigateName =
    role == 2
      ? t("business") + " " + t("information")
      : t("user") + " " + t("information");
  const navigateUrl =
    role == 2 ? "/business_details/" + id : "/userdetail/" + id;

  /** Sent OTP  */
  const initialValues = { email: "" };

  const onSubmit = (formData) => {
    formData.arabicStatus = arabicStatus;
    dispatch(loginByMobileRequest(formData));
    setotpShow(false);
    setloginShow(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: businesssotpValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  /** Form 2 */
  const loginValues = { email: email, otp: "" };

  const onLogin = (formData) => {
    formData.arabicStatus = arabicStatus;
    dispatch(loginUserRequest(formData));
  };

  const formik2 = useFormik({
    initialValues: loginValues,
    validationSchema: businesssigninValidator,
    onSubmit: onLogin,
  });
  const {
    handleSubmit: handleLogin,
    handleChange: handleUpdate,
    values: newvalues,
    touched: newtouched,
    errors: newerrors,
    setValues,
  } = formik2;

  /** User Register Form */
  const userRegisterValues = { email: "" };

  const onUserRegister = (formData) => {
    formData.arabicStatus = arabicStatus;
    dispatch(userRegisterRequest(formData));
    setuserRegisterShow(false);
    setuserLoginShow(true);
  };

  const userFormik = useFormik({
    initialValues: userRegisterValues,
    validationSchema: businesssotpValidator,
    onSubmit: onUserRegister,
  });
  const {
    handleSubmit: handleuserRegisterSubmit,
    handleChange: handleuserRegisterFields,
    values: userregistervalues,
    touched: userregistertouched,
    errors: userregistererrors,
  } = userFormik;

  /** User Login Form */
  const userLoginValues = { email: user_email, otp: "" };
  const onuserLogin = (formData) => {
    formData.arabicStatus = arabicStatus;
    dispatch(userLoginRequest(formData));
    setuserLoginShow(false);
  };

  const userLogin = useFormik({
    initialValues: userLoginValues,
    validationSchema: businesssigninValidator,
    onSubmit: onuserLogin,
  });
  const {
    handleSubmit: handleuserLogin,
    handleChange: userLoginFileds,
    values: userLoginvalues,
    touched: userLogintouched,
    errors: userLoginerrors,
  } = userLogin;

  /** Use Effect */

  useEffect(() => {
    if (id) {
      role == 2
        ? dispatch(linkGetRequest(id))
        : dispatch(getUserDetailRequest(id));
    }
  }, [id, role]);

  useEffect(() => {
    newvalues.email = email;
  }, [email]);

  useEffect(() => {
    userLoginvalues.email = user_email;
  }, [user_email]);

  /** Redirect */
  useEffect(() => {
    if (role == 2) {
      setUserDetailsData(getBussinessDetails?.general);

      if (isAuth && isLoginLoading == true) {
        if (getBussinessDetails?.general?.id != undefined) {
          navigate("/business_details/" + id);
          dispatch(resetSuccess());
        }
      }
    } else if (role == 3) {
      setUserDetailsData(getUserData);
      if (isAuth && loginSuccess == true) {
        navigate("/userdetail/" + id);
        dispatch(loginStateReset());
      }
    }
  }, [
    isAuth,
    loginSuccess,
    getBussinessDetails,
    role,
    isLoginLoading,
    id,
    resetSuccess,
  ]);

  /** Functions */
  const logoutBusiness = () => {
    localStorage.clear();
    setlogoutShow(!logoutShow);
    navigate("/");
    dispatch(resetStateRequest());
  };

  const changeLanguage = () => {
    if (lngcode == "ar") {
      setLngcode("en");
      i18n.changeLanguage("en");
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
    } else {
      setLngcode("ar");
      i18n.changeLanguage("ar");
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
    }
  };
  console.log(userregistererrors, 56445435);
  return (
    <>
      <headers>
        <section className="head-bg">
          <Container>
            <div className="navbarhead">
              <Row className="align-items-center">
                <Col className="rw-img" md={4}>
                  <Link to="/">
                    {/* <h1>Just Call </h1> */}
                    <img src={app} alt="" height="60px" />
                  </Link>
                </Col>
                <Col className="rw-bar" md={8}>
                  <div className="navbar-list">
                    <ul>
                      <li
                        className="lang"
                        onClick={(e) => {
                          changeLanguage();
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faLanguage} />
                        {lngcode == "en" ? "اﻟﻌﺮﺑﻴﺔ" : "English"}
                      </li>
                      <li>
                      <Link to="/">{t("home")}</Link>
                      </li>
                      <li>
                        <a href="#">{t("whats_treding")}</a>
                      </li>
                      <li>
                        <a href="#">{t("deals")}</a>
                      </li>
                      {/* <li className="rev">
                        {t("review")} <FontAwesomeIcon icon={faChevronDown} />
                        <div className="rev-dropdown">
                          <ul>
                            <li>
                              <a href="#">{t("writereview")}</a>
                            </li>
                            <li>
                              <a href="#">{t("post_photos")}</a>
                            </li>
                            <li>
                              <a href="#">{t("add_place")}</a>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                      {/* <li
                        onClick={() => {
                          if (!isAuth) setotpShow(!otpShow);
                        }}
                        className="cursor-pointer"
                      >
                        <span className="bus-btn ">{t("business")}</span>
                        <FontAwesomeIcon icon={faChartColumn} />{" "}
                        {t("free_listing")}
                      </li> */}
                      {/* {isAuth ? (
                        <li className="">
                          <span className="user_icon">
                            <FontAwesomeIcon
                              className="profile-listing-icon mx-2"
                              icon={faUser}
                              onClick={() => {
                                !logoutShow && isAuth
                                  ? setlogoutShow(!logoutShow)
                                  : setuserRegisterShow(!userRegisterShow);
                              }}
                            />
                          </span>
                        </li>
                      ) : (
                        <button
                          className="home-btn"
                          type="button"
                          onClick={() => {
                            !logoutShow && isAuth
                              ? setlogoutShow(!logoutShow)
                              : setuserRegisterShow(!userRegisterShow);
                          }}
                        >
                          {t("login")}
                        </button>
                      )} */}

                      {/* <li className="notify">
                        <FontAwesomeIcon icon={faBell} />
                      </li> */}
                      {!isAuth ? (
                        <>
                          <button className="signup-btn" onClick={Dropdown}>
                            {t("signup")} / {t("login")}
                          </button>
                          {visible && (
                            <div className="signup-dropdown">
                              <FontAwesomeIcon className="up" icon={faSortUp} />
                              <div className="dd-btn">
                                <button
                                  className="shop"
                                  onClick={() => {
                                    if (!isAuth) setotpShow(!otpShow);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faBuilding} />
                                  {t("for_business")}
                                </button>
                                <button
                                  className="business"
                                  onClick={() => {
                                    setuserRegisterShow(!userRegisterShow);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUser} />
                                  {t("for_users")}
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <li className="">
                          <span className="user_icon">
                            <FontAwesomeIcon
                              className="profile-listing-icon mx-2"
                              icon={faUser}
                              onClick={() => {
                                !logoutShow && isAuth
                                  ? setlogoutShow(!logoutShow)
                                  : setuserRegisterShow(!userRegisterShow);
                              }}
                            />
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="side-menu">
                    <FontAwesomeIcon icon={faBars} />
                    <div className="menu-dropdown">
                      <ul>
                        <li
                          className="lang"
                          onClick={(e) => {
                            changeLanguage();
                          }}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faLanguage} />
                          {lngcode == "en" ? "اﻟﻌﺮﺑﻴﺔ" : "English"}
                        </li>
                        <li>
                          <a href="#">{t("whats_treding")}</a>
                        </li>
                        <li className="rev">
                          {t("review")} <FontAwesomeIcon icon={faChevronDown} />
                          <div className="rev-dropdown">
                            <ul>
                              <li>
                                <a href="#">{t("writereview")}</a>
                              </li>
                              <li>
                                <a href="#">{t("post_photos")}</a>
                              </li>
                              <li>
                                <a href="#">{t("add_place")}</a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li
                          onClick={() => {
                            if (!isAuth) setotpShow(!otpShow);
                          }}
                        >
                          <span className="bus-btn">{t("business")}</span>
                          <a href="#">
                            <FontAwesomeIcon icon={faChartColumn} />{" "}
                            {t("free_listing")}
                          </a>
                        </li>
                        <button
                          className="home-btn"
                          onClick={() => {
                            !logoutShow && isAuth
                              ? setlogoutShow(!logoutShow)
                              : setuserRegisterShow(!userRegisterShow);
                          }}
                        >
                          {t("login")}
                        </button>

                        <li className="notify">
                          <FontAwesomeIcon icon={faBell} />
                        </li>

                        <button className="signup-btn" onClick={Dropdown}>
                          {t("signup")}
                        </button>
                        {visible && (
                          <div className="signup-dropdown">
                            <FontAwesomeIcon className="up" icon={faSortUp} />
                            <div className="dd-btn">
                              <button className="shop">
                                <FontAwesomeIcon icon={faUser} />
                                For Shoppers
                              </button>
                              <button className="business">
                                <FontAwesomeIcon icon={faBuilding} />
                                For Business
                              </button>
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* User Details*/}
              <Modal
                show={logoutShow}
                onHide={() => setlogoutShow(!logoutShow)}
                className="float-end"
              >
                <div>
                  <Row>
                    <div>
                      <section>
                        <Container>
                          <div>
                            <div className="profile-div">
                              <Row>
                                <Col
                                  md={4}
                                  lg={3}
                                  sm={1}
                                  className="p-0 width-10"
                                >
                                  <div className="profile-pic">
                                    <img src={profileimg} alt="" />
                                  </div>
                                </Col>
                                <Col md={8} lg={8} sm={9}>
                                  <div>
                                    <p>{t("hello")}</p>
                                    <h5>
                                      {userDetailsData?.name}
                                      {console.log(
                                        userDetailsData?.name,
                                        "logggin"
                                      )}
                                    </h5>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="profile-listing-card">
                              <ul>
                                <li onClick={() => navigate(navigateUrl)}>
                                  <FontAwesomeIcon
                                    className="profile-listing-icon mx-2"
                                    icon={faUser}
                                  />
                                  {navigateName}
                                </li>
                                <li
                                  onClick={() => {
                                    logoutBusiness();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="profile-listing-icon mx-2"
                                    icon={faRightFromBracket}
                                  />
                                  {t("logout")}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Container>
                      </section>
                    </div>
                  </Row>
                </div>
              </Modal>
            </div>
          </Container>
        </section>
      </headers>
      {/* Businss Send OTP */}
      <Modal show={otpShow} onHide={() => setotpShow(!otpShow)}>
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        setotpShow(!otpShow);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                  <h4 className="title">{t("get_otp")}</h4>
                  <Row>
                    <Col lg={12}>
                      <div class="form_group">
                        <label className="label-style">{t("email")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <p className="text-danger">
                          {arabicStatus ? errors?.email?.ar : errors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button type="submit" class="main-btn">
                        {t("submit")}
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* Business Login By OTP */}
      <Modal show={loginShow} onHide={() => setloginShow(!loginShow)}>
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button className="btn btn-warning">
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={(e) => {
                          e.preventDefault();
                          setloginShow(!loginShow);
                        }}
                      />
                    </button>
                  </div>
                  <h4 className="title">
                    {t("login_info")} {t("for_business")}
                  </h4>
                  <Row>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("email")}:</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={email}
                          onChange={handleUpdate}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? newerrors?.email?.ar
                            : newerrors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("otp")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("otp")}
                          name="otp"
                          value={newvalues.otp}
                          onChange={handleUpdate}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? newerrors?.otp?.ar
                            : newerrors.otp?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button class="main-btn">{t("login")}</button>
                      <button
                        class="main-btn mx-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        {t("resend")}
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* User Send OTP */}
      <Modal
        show={userRegisterShow}
        onHide={() => setuserRegisterShow(!userRegisterShow)}
      >
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleuserRegisterSubmit();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        setuserRegisterShow(!userRegisterShow);
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                  <h4 className="title">{t("get_otp")}</h4>
                  <Row>
                    <Col lg={12}>
                      <div class="form_group">
                        <label className="label-style">{t("email")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={userregistervalues.email}
                          onChange={handleuserRegisterFields}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? userregistererrors?.email?.ar
                            : userregistererrors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button
                        type="submit"
                        class="main-btn"
                        onClick={() => {
                          console.log("object1");
                        }}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
      {/* User Login By OTP */}
      <Modal
        show={userLoginShow}
        onHide={() => setuserLoginShow(!userLoginShow)}
      >
        <Row>
          <Col md={12}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleuserLogin();
              }}
            >
              <Row className="justify-content-center">
                <div className="add-listing-form modal-space details-listing-form wow fadeInUp">
                  <div className="text-end">
                    <button className="btn btn-warning">
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={(e) => {
                          e.preventDefault();
                          setuserLoginShow(!userLoginShow);
                        }}
                      />
                    </button>
                  </div>
                  <h4 className="title">
                    {t("login_info")} {t("for_users")}
                  </h4>
                  <Row>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("email")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("email")}
                          name="email"
                          value={user_email}
                          onChange={userLoginFileds}
                        />
                        <p className="text-danger">
                          {arabicStatus
                            ? userLoginerrors?.email?.ar
                            : userLoginerrors.email?.en}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div class="form_group">
                        <label className="label-style">{t("otp")} :</label>
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("your") + " " + t("otp")}
                          name="otp"
                          value={userLoginvalues.otp}
                          onChange={userLoginFileds}
                        />
                        <p className="text-danger">
                          {" "}
                          {arabicStatus
                            ? userLoginerrors?.otp?.ar
                            : userLoginerrors.otp?.en}
                        </p>
                      </div>
                    </Col>
                    <div class="button">
                      <button class="main-btn">{t("login")}</button>
                      <button
                        class="main-btn mx-1"
                        onClick={(e) => {
                          e.preventDefault();
                          handleuserRegisterSubmit();
                        }}
                      >
                        {t("resend")}
                      </button>
                    </div>
                    <div class="button"></div>
                  </Row>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Header;
