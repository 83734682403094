/** External Imports */
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  Button,
  Table,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RegisterBusiness1 from "./business_user/RegisterBussinesscopy";

import {
  faBriefcase,
  faHeart,
  faList,
  faPenToSquare,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import profileimg from "../assets/images/agent.svg";
/** Action (API Call) */
import {
  linkGetRequest,
  timeSendRequest,
} from "../store/bussiness/bussiness.action";
import {
  settingCountryRequest,
  settingLocationRequest,
  settingPlaceRequest,
} from "../store/Setting/setting.action";
import { resetStateRequest } from "../store/business_user/auth.action";
/** Model */
import { bussnessCatSelector } from "../store/bussiness/bussiness.model";
import { settingSelector } from "../store/Setting/setting.model";
import { categorySelector } from "../store/category/category.model";
/** Tab Contents */
import LinkDetail from "./business_user/LinkDetail";
import ImageUpload from "./business_user/ImageUpload";
import WorkingHours from "./business_user/WorkingHours";
import MenuPage from "./business_user/MenuPage";
import Amenties from "./business_user/Amenties";
import OfferDetail from "./business_user/OfferDetail";
import Map from "./business_user/Map";

function BusinessDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { i18n, t } = useTranslation()
  /* Selectors */
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { categories, subcaterory } = useSelector(categorySelector);
  /* States */
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [getcatid, setGetCatId] = useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [langSwitch, setLangSwitch] = useState(1);

  /* Function */
  const handleCategorySelect = (id) => {
    setGetCatId(parseInt(id));
    let category = categories?.data?.find((ele) => ele.id == id);
    console.log(category, "kdfskdsj");
    category.name === "Restaurants" ||
      category.name === "Hotel" ||
      category.name === "Hotels"
      ? setSelectedCategory(category)
      : setSelectedCategory(null);
  };

  const handleNext = () => {
    // setCurrentTabIndex((prevIndex) => prevIndex + 1);
    console.log("I am clicked");
  };

  /** Form Data */

  /* useEffect */
  useEffect(() => {
    console.log("first");
    dispatch(linkGetRequest(id));
  }, [id]);

  useEffect(() => {
    let category = categories?.data?.find(
      (ele) => ele.id == getBussinessDetails?.general?.category_id
    );

    setSelectedCategory(category);
    setGetCatId(getBussinessDetails?.general?.category_id)
  }, [getBussinessDetails]);

  console.log(id, "ids");
  console.log(getcatid,45675);

  return (
    <>
      <Header />
      <section>
        <Container className="mt-4">
          <div>
            <Row>
              <Col md={3} lg={3} className="p-0">
                <div>
                  <section>
                    <Container>
                      <div>
                        <div className="profile-div">
                          <Row>
                            <Col md={4} lg={3} sm={1} className="p-0 width-10">
                              <div className="profile-pic">
                                <img src={profileimg} alt="" />
                              </div>
                            </Col>
                            <Col md={8} lg={8} sm={9} className="widres">
                              <div>
                                <p>{t("hello")}</p>
                                <h5>
                                  {
                                    i18n.language == "ar" ? getBussinessDetails?.general?.arabic_name ?? t("welcome") + " " + t("user") : getBussinessDetails?.general?.name ?? t("welcome") + " " + t("user")
                                  }
                                </h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="profile-listing-card">
                          <ul>
                            <li
                              onClick={() =>
                                navigate(
                                  "/business_details/" +
                                  getBussinessDetails?.general?.id
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="profile-listing-icon"
                                icon={faUser}
                              />
                              {t("business") + " " + t("information")}
                            </li>
                            <li
                              onClick={() => {
                                console.log("object");
                                localStorage.clear();
                                navigate("/");
                                dispatch(resetStateRequest());
                              }}
                            >
                              <FontAwesomeIcon
                                className="profile-listing-icon"
                                icon={faRightFromBracket}
                              />
                              {t("logout")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Container>
                  </section>
                </div>
              </Col>
              <Col md={9} lg={9}>
                <Tabs
                  selectedIndex={currentTabIndex}
                  onSelect={(index) => setCurrentTabIndex(index)}
                >
                  <TabList>
                    <Tab>{t("general")}</Tab>
                    <Tab>{t("link")}</Tab>
                    <Tab>{t("images")}</Tab>
                    <Tab>{t("working_times")} </Tab>
                    <Tab> {t("amenities")}</Tab>
                    {selectedCategory && <Tab>{t("menu")}</Tab>}
                    <Tab>{t("offer_details")}</Tab>
                    {/* <Tab>{t("Map Place")}</Tab> */}
                  </TabList>

                  <TabPanel>
                    <RegisterBusiness1
                      onSelectCategory={handleCategorySelect}
                      onNext={handleNext}
                    />
                  </TabPanel>
                  <TabPanel>
                    <LinkDetail onNext={handleNext} />
                  </TabPanel>
                  <TabPanel>
                    <ImageUpload onNext={handleNext} />
                  </TabPanel>
                  <TabPanel>
                    <WorkingHours onNext={handleNext} />
                  </TabPanel>
                  <TabPanel>
                    <Amenties onNext={handleNext} />
                  </TabPanel>
                  {selectedCategory && (
                    <TabPanel>
                      <MenuPage getcatid={getcatid} />
                    </TabPanel>
                  )}                 
                  <TabPanel>
                    <OfferDetail />
                  </TabPanel>
                  {/* <TabPanel>
                    <Map />
                  </TabPanel> */}
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}
export default BusinessDetails;
