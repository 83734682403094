import { call, put, takeEvery } from "redux-saga/effects";
import { LocationActionTypes,PlaceActionTypes,CountryActionTypes,subLocationActionTypes, keywordActionTypes } from "./setting.model";
import * as SettingAction from "./setting.action";
import { getBYLocation, getByCountry,getByPlace,getKeywords,getSubLocation } from "./setting.api";


export function* handleFetchLocation(action){
    try{
      const response= yield call (getBYLocation);
      yield put(SettingAction.settingLocationSuccess(response))
      console.log(response, "settingLocation");
  
    }catch (e){
      yield put (SettingAction.settingLocationFailure(e))
    }
  }
  
  export function* handleFetchByCountry(action){
    try{
      const response= yield call (getByCountry);
      yield put(SettingAction.settingCountrySuccess(response))
      console.log(response, "settingCountry");
  
    }catch (e){
      yield put (SettingAction.settingCountryFailure(e))
    }
  }
  
  export function* handleFetchByPlace(action){
    try{
      const response= yield call (getByPlace);
      yield put(SettingAction.settingPlaceSuccess(response))
      console.log(response, "settingPlace");
  
    }catch (e){
      yield put (SettingAction.settingPlaceFailure(e))
    }
  }
  export function* handleSubLocation(action){
    try{
      const response= yield call (getSubLocation);
      yield put(SettingAction.subLocationSuccess(response))
      console.log(response, "settingLocation");
  
    }catch (e){
      yield put (SettingAction.subLocationFailure(e))
    }
  }
  
  export function* handlekeyword(action){
    try{
      const response= yield call (getKeywords);
      yield put(SettingAction.keywordGetSuccess(response))
      console.log(response, "settingLocation");
  
    }catch (e){
      yield put (SettingAction.keywordGetFailure(e))
    }
  }
  
  export function* SettingSaga(){
    yield takeEvery(LocationActionTypes.LOCATION_LIST_REQUEST, handleFetchLocation);
    yield takeEvery(CountryActionTypes.COUNTRY_LIST_REQUEST, handleFetchByCountry);
    yield takeEvery(PlaceActionTypes.PLACE_LIST_REQUEST, handleFetchByPlace)
    yield takeEvery(subLocationActionTypes.SUB_LOCATION_LIST_REQUEST, handleSubLocation)
    yield takeEvery(keywordActionTypes.GETKEYWORD_REQUEST, handlekeyword)
  }