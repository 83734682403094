import {
  LocationActionTypes,
  settingInitialState,
  CountryActionTypes,
  PlaceActionTypes,
  subLocationActionTypes,
  keywordActionTypes,
} from "./setting.model";
const reducer = (state = settingInitialState, action) => {
  switch (action.type) {
    case LocationActionTypes.LOCATION_LIST_REQUEST:
    case LocationActionTypes.LOCATION_LIST_FAILURE:
      return {
        ...state,
        locationLoading: true,
      };
    case LocationActionTypes.LOCATION_LIST_SUCCESS:
      console.log(action, "illl");
      return {
        ...state,
        locationLoading: false,
        location: action.payload.data.datas.data,
      };

    case CountryActionTypes.COUNTRY_LIST_REQUEST:
    case CountryActionTypes.COUNTRY_LIST_FAILURE:
      return {
        ...state,
        countryLoading: true,
      };
    case CountryActionTypes.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryLoading: false,
        country: action.payload.data.datas.data,
      };

    case PlaceActionTypes.PLACE_LIST_REQUEST:
    case PlaceActionTypes.PLACE_LIST_FAILURE:
      return {
        ...state,
        isLoading: true,
      };
    case PlaceActionTypes.PLACE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        place: action.payload.datas.data.data,
      };
    case subLocationActionTypes.SUB_LOCATION_LIST_REQUEST:
    case subLocationActionTypes.SUB_LOCATION_LIST_FAILURE:
      return {
        ...state,
        sub_locationLoading: true,
      };
    case subLocationActionTypes.SUB_LOCATION_LIST_SUCCESS:
      console.log(action, "illl");
      return {
        ...state,
        sub_locationLoading: false,
        sub_location: action.payload.data.subLocations,
      };

    case keywordActionTypes.GETKEYWORD_REQUEST:
      return {
        ...state,
        keyLoad: true,
      };
    case keywordActionTypes.GETKEYWORD_SUCCESS:
      return {
        ...state,
        keyLoad: false,
        keywordData:action?.payload?.data?.data
      };
    case keywordActionTypes.GETKEYWORD_FAILURE:
      return {
        ...state,
        keyLoad: false,
      };
    default:
      return state;
  }
};

export { reducer as SettingReducer };
